.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  width: 450px;
  height: 260px;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #eff2f7;
  margin-bottom: 32px;
}

.text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  color: #495057;
  margin-bottom: 32px;
}

.button {
  width: 116px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #34c38f;
  color: #ffffff;
  background-color: #34c38f;
  font-family: Poppins;
  font-size: 13px;
  text-align: center;
}

.button[disabled] {
  opacity: 0.5;
}
