.table-cell-amount, .billing-subscription {
  font-weight: 600;
}
.empty-billing-history-table {
  .table-responsive {
    .react-bootstrap-table {
      .table {
        .react-bs-table-no-data {
          .table-placeholder {
            height: 100px;
          }
        }
      }
    }
  }
}