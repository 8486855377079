.table-search {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }

  .search-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: 100;

    align-items: center;
    top: 0;
    pointer-events: none;
    left: 39px;
    color: #a1aab3;
    white-space: nowrap;

    .bold {
      display: inline-block;
      color: inherit;
      font-weight: 500;
      padding: 0 4px;
    }
  }

  :focus-within {
    .search-placeholder {
      display: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 12px;
    font-size: 18px;
    color: #495057;
  }

  .delete-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 18px;
    width: 10px;
    height: 10px;
    color: #2f665c;
  }

  input {
    min-width: 210px;
    width: 227px;
    height: 36px;
    border: 1px solid #ced4da;
    border-radius: 50px;
    background-color: #ffffff;
    padding-left: 38px;
    padding-right: 16px;
  }
}
