@mixin tableCellStyle() {
  color: #343A40;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.table-sort-icon {
  margin-left: 4px;
}

.store-owners-page, .employees-block, .billing-history-block {

  .table-light {
    --bs-table-bg: #f8f9fa;
  }

  .highlighted-text {
    font-weight: inherit;
    background-color: rgba(47, 102, 92, 0.2);
  }

  .store-owners-table, .employees-table, .billing-history-table{
    @include tableCellStyle();
    //min-height: 310px;
  }

  span.badge.rounded-pill {
    height: 27px;
    width: 95px;
    font-size: 11px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  tr {
    font-weight: normal;
  }

  ul.pagination.react-bootstrap-table-page-btns-ul {
    font-weight: normal;
  }

}