.image-wrapper {
  margin-right: 8px;

  .profile-logo {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}

.navbar-header {
  svg {
    cursor: pointer;
  }
}
