.formWrapper {
  display: flex;
  flex-direction: column;
}

.confirmButton {
  min-width: 127px;
  margin-left: auto;
}

.modalTitle {
  color: #343a40;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.modalButton {
  min-width: 127px;
  color: #ffffff !important;
  background-color: #556ee6 !important;
}
