.input {
  height: 37px;
  font-family: Poppins;
  font-size: 13px;
  padding: 9px 20px 9px 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  color: #343a40;
  outline: none;
  margin-top: 20px;
}

.errorInput {
  border-color: #f46a6a;
}

.textarea {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  margin-top: 20px;
  padding: 9px 20px 9px 12px;
  color: #343a40;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: calc(1.5em + 0.94rem + 2px);
  resize: vertical;
  overflow: hidden;
  outline: none;
}

.errorTextarea {
  border-color: #f46a6a;
}

.error {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  font-family: Poppins;
  font-size: 80%;
  color: #f46a6a;
}
