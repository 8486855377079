.rounded-cropper {
  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    border-radius: 50%;
    outline: 0;
  }
  .cropper-face {
    background-color: inherit !important;
  }
  .cropper-view-box {
    outline: inherit !important;
  }
}
