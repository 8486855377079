.rectangle {
  width: 141px;
  height: 36px;
  border-radius: 4px;
  &.red {
    background-color: #f6d4d4;
    color: #f46a6a;
  }
  &.blue {
    background-color: #dde2fa;
    color: #556ee6;
  }
  &.green {
    background-color: rgba(52, 195, 143, 0.18);
    color: #34c38f;
  }
  &.orange {
    background-color: rgba(241, 145, 81, 0.18);
    color: #f1734f;
  }
}

.over-divider {
  min-height: 52px;
}