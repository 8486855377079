.store-owners-list-toolbar {
  margin-bottom: 24px;
  margin-top: 4px;
}

.subscription-filter {
  width: 195px;
  font-weight: normal;
  [class*='indicatorSeparator'] {
    display: none;
  }
}