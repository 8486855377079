.users-table{
    min-height: 70vh;
    
    &--filters{
        min-height: pxToRem(50);
        margin-bottom: pxToRem(10);

        &--dropdown{
            padding: pxToRem(16);
            padding-bottom: pxToRem(19);
            width: pxToRem(217);
            font-size: pxToRem(14);

            &__title{
                font-size: pxToRem(14);
                font-weight: 600;
                margin-bottom: pxToRem(20);
            }

            &__next-section{
                margin-top: pxToRem(15)
            }

            &__toggle{
                box-sizing: border-box;
                height: pxToRem(37);
                width: pxToRem(109);
                border: 1px solid #556EE6!important;
                border-radius: pxToRem(30)!important;
                color: #556EE6!important;
                font-size: pxToRem(13);
                line-height: pxToRem(20);
                text-align: center;
                outline: none;
            }
    
            &__toggle:hover{
                color: #556EE6;
                background-color: rgba($color: #556EE6, $alpha: 0.2);
                outline: none;
            }

            .form-check{
                margin-top: pxToRem(10)
            }
        }

    }
}