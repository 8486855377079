.delete-btn {
  border-radius: 30px;
  width: 112px;
  height: 36px;
}

.store-owner-avatar {
  height: 52px;
  width: 52px;
  border-radius: 50px;
  background-color: whitesmoke;
  object-fit: cover;
}

button.modal-button {
  width: 127px;
}