.dropzone-file {
  display: block;
  margin-top: 2rem;
  border: 2px dashed #ced4da;
  padding: .625rem .125rem;
  cursor: pointer;

  div {
    outline: none;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cloud {
      font-size: 2.3rem;
    }

    &__text {
      font-size: 1rem;
      text-align: center;
      padding: 0 .625rem;
    }
  }

  .choose-file {
    display: inline-block;
    background-color: #e1e1e1;
    font-size: .8125rem;
    color: #495057;
    padding: .1875rem .5rem;
    border-radius: 4px;
    border: solid 1px #e1e1e1;
    outline: none;
    cursor: pointer;
  }

  .drag-active {
    border: 2px dashed #ced4da;
    border-radius: 6px;
  }
}