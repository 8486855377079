.info-pages {
  padding-top: 2rem;
}

.privacy-footer {
  padding: 1rem 0;

  a {
    margin: 0 .5rem;
    color: #ababab;
    font-size: .8rem;
    font-weight: 500;
    transition: .3s ease color;

    &:hover {
      color: #495057;
    }
  }
}