.formWrapper {
  display: flex;
  width: 100%;
}

.leftPart {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.rightPart {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.button {
  width: 127px;
  height: 35px;
  padding: 9px 0 8px;
  border: none;
  border-radius: 4px;
  background-color: #556ee6;
  color: #ffffff;
  margin-top: 20px;
}

.button[disabled] {
  opacity: 0.5;
}
