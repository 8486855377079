.edit-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-user-btn {
  width: 100px;
  height: 40px;
}

.edit-avatar-wrapper {
  display: flex;
  justify-content: center;
}
